'use client'

import React, { ReactNode } from 'react'

import Box, { BoxProps } from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

export default function ExternalPageContent({ children, sx, ...props }: { children: ReactNode } & BoxProps) {
  const theme = useTheme()

  return (
    <Box
      sx={{
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(0, 8),
        },
        color: theme.palette.secondary.main,
        textAlign: 'center',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  )
}
