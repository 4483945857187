'use client'

import { ReactNode, useEffect } from 'react'

import useLogout from 'hooks/useLogout'
import { getJWTCookie, removeRedesignOptInCookie } from 'redesignStore/api/utils/common'

/**
 * On unauthenticated pages users should generally be logged out automatically.
 * This is based on pen test feedback about logged in users being unable to 'manage their session' from external
 * pages that have no sidebar options for logout or header items showing that a user is logged in.
 */
export default function AutoLogout(): ReactNode {
  const logout = useLogout()

  useEffect(() => {
    const logUserOutIfLoggedIn = () => {
      if (getJWTCookie()) {
        try {
          return logout()
        } catch (e) {
          console.info('Ignoring error encountered when logging user out', e)
        } finally {
          removeRedesignOptInCookie()
        }
      }
    }

    const result = logUserOutIfLoggedIn()

    return () => result?.abort()
  }, [logout])

  return null
}
