import * as process from 'process'

import { useEffect, useState } from 'react'

import getPkce from 'oauth-pkce'

export default function usePkceChallenge() {
  const [pkceChallenge, setPkceChallenge] = useState<{ verifier: string; challenge: string }>()

  useEffect(() => {
    // Web Crypto libraries aren't available in http:// (i.e. AT environments). Hard code a verifier/challenge for these environments.
    const generatePKCEChallenge = async () => {
      const { verifier, challenge } =
        !window.crypto?.subtle && process.env.NEXT_PUBLIC_USE_CONSTANT_PKCE_IF_HTTP === 'true'
          ? {
              verifier:
                'QUEB7h179cbVb1iP-Y.wDCWgg76luoYN3yLmJ0onQ1C9mP9~oGc55d205H~boz3q01_9rpAJ79DKKCc7nGfaRGBM0qeyDAjFe--iXjreBBvAjslxdEYzqI9.b8lBnZlA',
              challenge: '47DEQpj8HBSa-_TImW-5JCeuQeRkm5NMpJWZG3hSuFU',
            }
          : await new Promise<{ verifier: string; challenge: string }>(resolve => {
              getPkce(43, (error, { verifier, challenge }) => {
                if (error) throw error
                resolve({ verifier, challenge })
              })
            })

      return { verifier, challenge }
    }

    generatePKCEChallenge().then(c => setPkceChallenge(current => current || c))

    return () => setPkceChallenge(undefined)
  }, [])

  return pkceChallenge
}
