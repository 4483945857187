'use client'

import React from 'react'

import Box, { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const Footer = styled((props: BoxProps) => <Box component="footer" {...props} />)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 8),
  },
  color: theme.palette.secondary.main,
  textAlign: 'center',
}))

export default {}
