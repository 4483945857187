'use client'

import { useEffect, useState } from 'react'

import { useSearchParams } from 'next/navigation'

export default function useSanitisedTarget() {
  const searchParams = useSearchParams()
  const target = searchParams?.get('target')

  const [origin, setOrigin] = useState<string>()

  useEffect(() => {
    setOrigin(window.location.origin)
  }, [])

  // Example input that this protects us against: `?target=//website.com`
  const sourceAndDestinationOriginMatch =
    origin && target && new URL(window.location.origin).origin === new URL(target, window.location.origin).origin

  return sourceAndDestinationOriginMatch ? new URL(`${origin}${target}`).pathname : undefined
}
