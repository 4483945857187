'use client'

import React from 'react'

import { createSvgIcon } from '@mui/material'

export default createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 29">
    <g fill="none" fillRule="evenodd">
      <path
        d="M8.12 12.136h2.53v8.954H8.12v-8.954Zm1.2-1.12h-.018c-.916 0-1.51-.685-1.51-1.551 0-.885.611-1.556 1.546-1.556.934 0 1.508.67 1.526 1.553 0 .867-.592 1.554-1.544 1.554ZM20.778 21.09h-2.87v-4.635c0-1.212-.45-2.04-1.437-2.04-.755 0-1.175.558-1.37 1.098-.073.192-.062.461-.062.731v4.846h-2.843s.036-8.209 0-8.955h2.843v1.405c.168-.614 1.077-1.491 2.527-1.491 1.798 0 3.212 1.289 3.212 4.061v4.98Z"
        fill="currentColor"
      />
    </g>
  </svg>,
  'LinkedIn'
)
