'use client'

import { AnchorHTMLAttributes, useEffect, useState } from 'react'

import useSupportEmail from 'components/templates/ExternalPage/useSupportEmail'
import isIframe from 'utils/isIframe'

export default function useSupportLinkProps(): Pick<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  'href' | 'target' | 'rel'
> {
  const supportEmail = useSupportEmail()
  const [isEmbeddedInIframe, setEmbeddedInIframe] = useState(false)

  useEffect(() => {
    setEmbeddedInIframe(isIframe())
  }, [])

  if (isEmbeddedInIframe) {
    return {
      href: 'https://help.createiq.tech/kb-tickets/new',
      target: '_blank',
      rel: 'noopener noreferrer',
    }
  }

  return {
    href: `mailto:${supportEmail}`,
  }
}
