'use client'

import React from 'react'

import { useTheme } from '@mui/material/styles'

import LinklatersCreateIQLogo from 'components/images/LinklatersCreateIQLogo'

export default function ExternalLayoutLogo() {
  const theme = useTheme()

  return <LinklatersCreateIQLogo style={{ height: '20px', color: theme.palette.primary.main }} />
}
