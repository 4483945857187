'use client'

import React, { ReactNode } from 'react'

import BackIcon from '@mui/icons-material/KeyboardBackspace'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useRouter } from 'next/navigation'

export default function ExternalPageHeader({ children, back }: { children: ReactNode; back?: ReactNode }) {
  const theme = useTheme()
  const router = useRouter()

  return (
    <Box
      component="header"
      sx={{
        textAlign: 'center',
        position: 'relative',
        color: theme.palette.common.black,
      }}
    >
      {typeof back === 'boolean' ? (
        <IconButton aria-label="Back" sx={{ position: 'absolute', left: 0 }} onClick={() => router.back()}>
          <BackIcon />
        </IconButton>
      ) : typeof back === 'string' ? (
        <IconButton aria-label="Back" href={back} sx={{ position: 'absolute', left: 0 }}>
          <BackIcon />
        </IconButton>
      ) : (
        back
      )}
      <Typography variant="headlineMedium">{children}</Typography>
    </Box>
  )
}
