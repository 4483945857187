'use client'

import React from 'react'

import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

import CreateIQLogo from 'components/images/CreateIQLogo'

export default function ExternalLayoutLogo() {
  const theme = useTheme()

  return (
    <Box
      sx={{
        color: theme.palette.primary.main,
      }}
    >
      <CreateIQLogo width="111" height="20" />
    </Box>
  )
}
