import { useCallback } from 'react'

import { useApiLogoutMutation, useAuthServiceLogoutMutation } from 'redesignStore/api/loginApi/loginApi'
import useAuthEndpoints from 'redesignStore/api/loginApi/utils/useAuthEndpoints'
import { removeJWTCookie } from 'redesignStore/api/utils/common'

export default function useLogout() {
  const [apiLogout] = useApiLogoutMutation()
  const [authServiceLogout] = useAuthServiceLogoutMutation()

  const { authEndpoints } = useAuthEndpoints()

  return useCallback(() => {
    // Try to log out of Okta and regardless of how that goes make sure to call the API's logout endpoint and remove the JWT
    try {
      if (authEndpoints) {
        // Purposefully un-awaited as this is a low priority call and the user doesn't need to be kept waiting while it happens
        authServiceLogout({ configuration: authEndpoints })
      }

      return apiLogout()
    } finally {
      removeJWTCookie()
    }
  }, [apiLogout, authServiceLogout, authEndpoints])
}
