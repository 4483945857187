'use client'

import React, { ReactNode } from 'react'

import Link from '@mui/material/Link'

import useSupportEmail from 'components/templates/ExternalPage/useSupportEmail'
import useSupportLinkProps from 'components/templates/ExternalPage/useSupportLinkProps'

export default function SupportEmailLink({ children }: { children?: ReactNode }) {
  const supportEmail = useSupportEmail()
  const supportLinkProps = useSupportLinkProps()

  return (
    <Link component="a" {...supportLinkProps}>
      {children || supportEmail}
    </Link>
  )
}
