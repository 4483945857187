import baseApi from 'redesignStore/api/baseApi'
import {
  AuthEndpointsResponse,
  AuthnRequest,
  AuthnResponse,
  AuthServerRequest,
  AuthServerResponse,
  ExchangeCodeRequest,
  ExchangeCodeResponse,
  ForgotPasswordRequest,
  ResetPasswordRequest,
} from 'types/Auth'

const loginApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getAuthEndpoints: build.query<AuthEndpointsResponse, void>({
      query: () => '/authEndpoints',
    }),
    submitEmail: build.mutation<AuthServerResponse, AuthServerRequest>({
      query: body => ({
        url: '/authServer',
        method: 'POST',
        body,
      }),
    }),
    signIn: build.mutation<AuthnResponse, AuthnRequest & { configuration: AuthEndpointsResponse }>({
      async queryFn({ configuration, ...body }) {
        try {
          const result = await fetch(configuration.auth.authn, {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
              accept: 'application/json',
            },
            body: JSON.stringify(body),
          })
          const data = await result.json()

          if (result.ok) {
            return { data }
          }

          return {
            error: {
              status: result.status,
              data,
            },
          }
        } catch (error) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              data: error,
              error: `${error}`,
            },
          }
        }
      },
    }),
    exchangeCode: build.mutation<ExchangeCodeResponse, ExchangeCodeRequest>({
      query: body => ({
        url: '/auth/exchangeCode',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'Context' }],
    }),
    forgottenPassword: build.mutation<void, ForgotPasswordRequest>({
      query: body => ({
        url: '/auth/forgottenPassword',
        method: 'POST',
        body,
      }),
    }),
    validateResetCode: build.query<void, string>({
      query: code => ({
        url: '/auth/forgottenPassword/verifyCode',
        params: {
          resetCode: code,
        },
      }),
    }),
    resetPassword: build.mutation<void, ResetPasswordRequest>({
      query: body => ({
        url: '/auth/forgottenPassword/useCode',
        method: 'PUT',
        body,
      }),
    }),
    apiLogout: build.mutation<void, void>({
      query: body => ({
        url: '/auth/logout',
        method: 'POST',
        body,
      }),
    }),
    authServiceLogout: build.mutation<void, { configuration: AuthEndpointsResponse }>({
      async queryFn({ configuration }) {
        try {
          const result = await fetch(configuration.auth.logoutUrl, {
            method: 'DELETE',
            credentials: 'include',
          })

          if (result.ok) {
            return { data: '' as unknown as void }
          }

          const data = await result.json()
          return {
            error: {
              status: result.status,
              data,
            },
          }
        } catch (error) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              data: error,
              error: `${error}`,
            },
          }
        }
      },
    }),
  }),
  overrideExisting: module.hot?.status() === 'apply',
})

export const {
  useGetAuthEndpointsQuery,
  useLazyGetAuthEndpointsQuery,
  useSubmitEmailMutation,
  useSignInMutation,
  useExchangeCodeMutation,
  useForgottenPasswordMutation,
  useValidateResetCodeQuery,
  useResetPasswordMutation,
  useAuthServiceLogoutMutation,
  useApiLogoutMutation,
} = loginApi

export default loginApi
